/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_item-element_93a1u_1ramd_145:not(#\9) {
  position: relative;
  z-index: 1;
  border-block: var(--border-item-width-yel47s, 2px) solid transparent;
  border-inline: var(--border-item-width-yel47s, 2px) solid transparent;
  list-style: none;
  padding-block: 0;
  padding-inline: 0;
  color: var(--color-text-dropdown-item-default-wpxsrk, #0f141a);
  margin-block-start: calc(-1 * var(--border-width-field-h1g7tw, 2px));
  cursor: pointer;
}
.awsui_item-element_93a1u_1ramd_145.awsui_disabled_93a1u_1ramd_157:not(#\9) {
  cursor: default;
  color: var(--color-text-dropdown-item-disabled-7fg5u8, #b4b4bb);
}
.awsui_item-element_93a1u_1ramd_145:not(#\9):first-child {
  margin-block-start: 0;
}
.awsui_item-element_93a1u_1ramd_145.awsui_show-divider_93a1u_1ramd_164:not(#\9) {
  border-block-end: var(--border-item-width-yel47s, 2px) solid var(--color-border-dropdown-group-2xoluv, #c6c6cd);
}
.awsui_item-element_93a1u_1ramd_145.awsui_highlighted_93a1u_1ramd_167:not(#\9) {
  color: var(--color-text-dropdown-item-highlighted-rnfxxj, #0f141a);
  z-index: 2;
  background-color: var(--color-background-dropdown-item-hover-zhzc84, #f3f3f7);
  border-color: var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
}
.awsui_item-element_93a1u_1ramd_145.awsui_highlighted_93a1u_1ramd_167.awsui_disabled_93a1u_1ramd_157:not(#\9) {
  color: var(--color-text-dropdown-item-dimmed-7c5xo0, #b4b4bb);
  border-color: var(--color-border-dropdown-item-dimmed-hover-6qayyi, #8c8c94);
  background-color: var(--color-background-dropdown-item-dimmed-dlm6wk, transparent);
}
.awsui_item-element_93a1u_1ramd_145.awsui_highlighted_93a1u_1ramd_167.awsui_is-focused_93a1u_1ramd_182:not(#\9) {
  border-color: var(--color-border-dropdown-item-focused-5afjvu, #424650);
  box-shadow: inset 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_menu-item_93a1u_1ramd_187:not(#\9) {
  min-inline-size: 0;
  word-break: break-word;
  display: flex;
  align-items: flex-start;
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: var(--space-l-t419sm, 20px);
  color: inherit;
  text-decoration: none;
  /* stylelint-disable selector-max-type */
  /* stylelint-enable selector-max-type */
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9) {
  padding-block-end: calc(var(--space-xxs-p8yyaw, 4px) + var(--space-xxxs-zbmxqb, 2px));
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9), .awsui-mode-entering .awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9):focus {
  outline: none;
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9):active, .awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9):focus, .awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199.awsui_current-breadcrumb_93a1u_1ramd_235:not(#\9) {
  font-weight: var(--font-weight-button-c91p4t, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-breadcrumb-current-9h12sf, #656871);
  font-weight: 700;
  text-decoration: none;
}
.awsui_menu-item_93a1u_1ramd_187.awsui_link-style_93a1u_1ramd_199.awsui_link-style-highlighted_93a1u_1ramd_243:not(#\9) {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_menu-item_93a1u_1ramd_187:not(#\9):focus {
  outline: none;
}
.awsui_has-category-header_93a1u_1ramd_249 > .awsui_menu-item_93a1u_1ramd_187:not(#\9), .awsui_has-category-header_93a1u_1ramd_249 > .awsui_item-tooltip-wrapper_93a1u_1ramd_249 > .awsui_menu-item_93a1u_1ramd_187:not(#\9), .awsui_has-category-header_93a1u_1ramd_249:not(#\9):not(.awsui_has-checkmark_93a1u_1ramd_249) > span > .awsui_menu-item_93a1u_1ramd_187 {
  padding-inline-start: calc(var(--space-xs-zb16t3, 8px) + var(--space-l-t419sm, 20px));
}

.awsui_icon_93a1u_1ramd_253:not(#\9) {
  padding-inline-end: var(--space-xxs-p8yyaw, 4px);
  flex-shrink: 0;
}
.awsui_icon_93a1u_1ramd_253.awsui_checkmark_93a1u_1ramd_257:not(#\9) {
  color: var(--color-text-status-info-7rlubr, #006ce0);
}
.awsui_icon_93a1u_1ramd_253.awsui_disabled_93a1u_1ramd_157:not(#\9) {
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}

.awsui_external-icon_93a1u_1ramd_264:not(#\9) {
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}